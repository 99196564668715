<template>
  <div class="global">
    <div class="global-header">
      <div class="banner-inner">
        <p class="g-banner-p1">快企务15年专业注册海外公司</p>
        <p class="g-banner-p2">香港/德国/美国/BVI公司等地区注册公司</p>
        <p class="g-banner-p3">全球热门公司注册地均可申请</p>
      </div>
    </div>
    <div class="g-business">
      <input class="g-busi-input" type="text" name="" placeholder="注册公司地区（或待推荐）">
      <input class="g-busi-input input-1" type="text" name="" placeholder="手机号/微信号">
      <p class="busi-btn" @click="Dialog = true">业务办理</p>
    </div>
    <div class="g-use">
      <p class="re-title margin-80">注册海外公司用途</p>
      <div class="g-use-box">
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_1.png" alt="">
          <p class="g-use-p">税务筹划</p>
        </div>
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_2.png" alt="">
          <p class="g-use-p">控股其它公司</p>
        </div>
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_3.png" alt="">
          <p class="g-use-p">国际贸易</p>
        </div>
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_4.png" alt="">
          <p class="g-use-p">商业性投资</p>
        </div>
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_5.png" alt="">
          <p class="g-use-p">境外上市</p>
        </div>
        <div class="g-use-inner">
          <img class="g-use-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_1_6.png" alt="">
          <p class="g-use-p">提升国际形象</p>
        </div>
      </div>
    </div>
    <div class="g-hot">
      <p class="re-title">热门公司注册地</p>
      <div class="g-hot-box">
        <el-carousel :interval="5000" arrow="always" height="740px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="g-hot-content">
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_1bvi.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">bvi公司注册</p>
                  <p class="fina-p2">即英属维京群岛，免交离岸贸易税，保护受益人身份</p>
                  <p class="fina-p3" @click="toUrl('bvi')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_2cayman.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">开曼公司注册</p>
                  <p class="fina-p2">无限制贸易发展，无外汇管制，无需申报或缴纳税项</p>
                  <p class="fina-p3" @click="toUrl('kaiman')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_3singapore.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">新加坡公司注册</p>
                  <p class="fina-p2">国际金融中心，税收极低，宽松的外汇管制，成熟的信用体系</p>
                  <p class="fina-p3" @click="toUrl('singapore')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_4hongkong.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">中国香港公司注册</p>
                  <p class="fina-p2">多项利企政策，国内企业跳板，未来新机遇</p>
                  <p class="fina-p3" @click="toUrl('HongKong')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_5usa.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">美国公司注册</p>
                  <p class="fina-p2">经营范围受限少，无外汇管制，部分州税率低、税种少</p>
                  <p class="fina-p3" @click="toUrl('America')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_6england.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">英国公司注册</p>
                  <p class="fina-p2">注册时间短，公司管理便捷，外汇进出自由，国际法律地位高</p>
                  <p class="fina-p3" @click="toUrl('Britain')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_7taiwan.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">中国台湾公司注册</p>
                  <p class="fina-p2">出口有减税优惠，便于提升公司形象及信誉</p>
                  <p class="fina-p3" @click="toUrl('Taiwan')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_8marshall.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">马绍尔公司注册</p>
                  <p class="fina-p2">资料绝对保密，税收优惠，不受外汇管制，是集资极佳工具</p>
                  <p class="fina-p3" @click="toUrl('Marshall')">查看详情 ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="g-hot-content">
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_9germany.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">德国公司注册</p>
                  <p class="fina-p2">德国市场完善国际品牌所在地跨境电商行业发展迅速</p>
                  <p class="fina-p3" @click="toUrl('Germany')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_10france.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">法国公司注册</p>
                  <p class="fina-p2">低运营成本，相对较低国税率，利于提升企业及产品品牌形象</p>
                  <p class="fina-p3" @click="toUrl('registerFrench')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_11macao.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">中国澳门公司注册</p>
                  <p class="fina-p2">低税率制，经营成本地，回程投资享受“超国民待遇”</p>
                  <p class="fina-p3" @click="toUrl('registerMacau')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_12netherlands.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">荷兰公司注册</p>
                  <p class="fina-p2">欧盟成员，关税和增值税实行递延政策，无外汇管制，荷兰公司参股所得免税</p>
                  <p class="fina-p3" @click="toUrl('registerNetherlands')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_13switzerland.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">瑞士公司注册</p>
                  <p class="fina-p2">无外汇管制，相对完善的资本市场，瑞士全球产业集群和金融中心</p>
                  <p class="fina-p3" @click="toUrl('registerSwiss')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_14cyprus.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">塞浦路斯公司注册</p>
                  <p class="fina-p2">欧盟互免关税，法律氛围良好，自由经济体系</p>
                  <p class="fina-p3" @click="toUrl('registerCyprus')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_15dubai.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">迪拜公司注册</p>
                  <p class="fina-p2">注册费用低，所有权完全来于国外享受迪拜当地进出口税收优惠待遇，免税经营长达15-50年，移民政策宽松</p>
                  <p class="fina-p3" @click="toUrl('registerDubai')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_16korea.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">韩国公司注册</p>
                  <p class="fina-p2">亚洲第三大经济中心，亚太地区收益中心，IT强国氛围，利好的外商投资政策</p>
                  <p class="fina-p3" @click="toUrl('registerKorea')">查看详情 ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="g-hot-content">
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_17anguilla.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">安圭拉公司注册</p>
                  <p class="fina-p2">享受零税率，无实收资本要求，信息保密，可用任何语言作公司名称</p>
                  <p class="fina-p3" @click="toUrl('registerAnquila')">查看详情 ></p>
                </div>
              </div>
              <div class="g-hot-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_2_18malta.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">马耳他公司注册</p>
                  <p class="fina-p2">政治经济相对稳定，欧洲著名避税天堂，社会保证体系完备，“四位一体”国</p>
                  <p class="fina-p3" @click="toUrl('registerMalta')">查看详情 ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <p class="g-hot-more">查看更多请详询专业顾问 ></p>
    </div>
    <div class="g-question">
      <p class="re-title">常见问题</p>
      <p class="re-title1">资深顾问 <span class="blue-style">1</span>对 <span class="blue-style">1</span>专业指导</p>
      <div class="g-que-box">
        <div class="g-que-left">
          <div class="left-title">
            常见问题
            <img class="title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/line.png" alt="">
          </div>
          <div class="left-inner">
            <p class="left-inner-p">****名称是否被注册？</p>
            <p class="left-inner-p">需要本人到场吗？</p>
            <p class="left-inner-p">费用是多少？</p>
            <p class="left-inner-p">需要提供股东信息吗?</p>
            <p class="left-inner-p">需要什么条件？</p>
            <p class="left-inner-p">流程是怎样的？</p>
            <p class="left-inner-p">注册资金需要实缴吗？</p>
            <p class="left-inner-p">注册完成后可以开户吗？</p>
          </div>
        </div>
        <div class="g-que-right">
          <el-carousel :interval="5000" arrow="always" height="320px" indicator-position="outside" :autoplay="true">
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_1.png" alt="" />
                  <p class="name">Jack</p>
                  <p class="job">高级顾问经理</p>
                  <p class="right-inner-p1">十余年海外各国公司注册、国际公证认证以及股权架构搭建的丰富经验，涉外企业咨询经历丰富；能为客户提供专业的服务以及合适的解决方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_2.png" alt="" />
                  <p class="name">Cherry</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">在注册方面拥有丰富的专业知识和独到的见解，为客户在前期注册提供方案，提供专业知识解答、最佳战略构想等深层次的顾问服务</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_4.png" alt="" />
                  <p class="name">Jessica</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">精通贸促会认证、各使领馆认证、海牙认证和中国委托公证认证等国内外公证认证类业务，有多年国际公司设立的经验</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_6.png" alt="" />
                  <p class="name">Tracy</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">精通国内外公司注册及财税筹划，熟悉国内商标版权</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_14.png" alt="" />
                  <p class="name">Richard </p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">擅长根据客户的需求提供合理的架构方案，并在最快的时间完成公司的设立；并且善于通过涉外公司的设立给予海外品牌拓展的综合方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_7.png" alt="" />
                  <p class="name">Maggie</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">十余年离岸公司注册、国际公证认证以及股权架构搭建的丰富经验，涉外企业咨询经理丰富，获得各新老客户的一致好评</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_8.png" alt="" />
                  <p class="name">Jane</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">精通国内外税务制度，熟悉全球100多个国家及地区离岸/在岸公司设立，能为客户量身定制税务规划、海外上市架构搭建等方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_9.png" alt="" />
                  <p class="name">Gavin</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">行业深耕10年有余，精通于股权架构搭建、擅长企业战略规划，能够精准把握客户需求，并给予全面系统化的合理解决方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_10.png" alt="" />
                  <p class="name">Dereck</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">为客户提供专业化知识和合规的运营意见，量身打造最合适的解决方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_11.png" alt="" />
                  <p class="name">Jason</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">擅长迅速精准地把握客户的需求，为客户提供专业的咨询，使之降低成本，树立国际品牌，从而让客户立于不败之地</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_12.png" alt="" />
                  <p class="name">Mona </p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">具备扎实的涉外企业注册专业基础、丰富的商标代理经验，从事市场营销多年，有着丰富的市场管理营销经验</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_15.png" alt="" />
                  <p class="name">Daisy</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">从事涉外行业多年，精通涉外律师公证、海牙认证、商标代理等业务</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_16.png" alt="" />
                  <p class="name">Guoqing Yang </p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">熟悉国内外公司设立，通过公司设立定制公司运营相关方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_17.png" alt="" />
                  <p class="name">Lisa</p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">精通国内外公司注册及财税筹划，并熟悉国际公证认证（中国委托公证人公证、国内外使领馆认证、海牙认证等）等相关业务</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="right-box">
                <div class="right-inner">
                  <img class="right-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/pic_3_18.png" alt="" />
                  <p class="name">Shirley </p>
                  <p class="job">高级顾问</p>
                  <p class="right-inner-p1">具有多年的市场营销经验以及大客户的服务经验，精准把握客户需求并给予相应解决方案</p>
                  <p class="right-inner-p2" @click="Dialog = true">立即咨询 ></p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <p class="g-que-bottom">任何疑问请拨打: <span class="g-que-span">400 860 5600</span></p>
    </div>
    <div class="g-advantage">
      <p class="re-title">快企务 · 全球公司注册优势</p>
      <div class="g-adv-box">
        <div class="g-adv-inner">
          <img class="g-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_2_1.png" alt="">
          <p class="g-adv-p1">十余年经验</p>
          <p class="g-adv-p2">十余年海外公司注册服务经验，专业的海外注册业务</p>
        </div>
        <div class="g-adv-inner">
          <img class="g-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_2_2.png" alt="">
          <p class="g-adv-p1">安全保密</p>
          <p class="g-adv-p2">我司拥有完善的保密系统，保护客户隐私不外泄</p>
        </div>
        <div class="g-adv-inner">
          <img class="g-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_2_3.png" alt="">
          <p class="g-adv-p1">全程服务</p>
          <p class="g-adv-p2">一站式专业服务，从注册到年审、银行开户、审计报税提供全方面协助</p>
        </div>
        <div class="g-adv-inner">
          <img class="g-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_2_4.png" alt="">
          <p class="g-adv-p1">公正认证</p>
          <p class="g-adv-p2">价格透明，统一报价无隐形消费</p>
        </div>
        <div class="g-adv-inner">
          <img class="g-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/icon_2_5.png" alt="">
          <p class="g-adv-p1">专业高效</p>
          <p class="g-adv-p2">根据不同需求，为客户制定专业快捷的解决方案</p>
        </div>
      </div>
    </div>

    <div class="g-submit">
      <p class="g-sub-p1">专业顾问为您的企业保驾护航</p>
      <p class="g-sub-p2">十余年老品牌 国家正规备案代理公司</p>
      <div class="g-sub-box">
        <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
          <div class="g-sub-inner">
            <el-form-item label="" prop="name">
              <el-input class="g-sub-input" v-model="form.name" type="text" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="g-sub-input" type="text" v-model="form.mobile" placeholder="请输入您的手机号"></el-input>
            </el-form-item>
          </div>
          <div class="g-sub-inner">
            <el-form-item label="" prop="direction">
              <el-select class="g-sub-select" v-model="form.direction" placeholder="请选择注册意向">
                <el-option label="贸易" value="贸易"></el-option>
                <el-option label="投资" value="投资"></el-option>
                <el-option label="品牌" value="品牌"></el-option>
                <el-option label="避税" value="避税"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="country">
              <el-select class="g-sub-select" v-model="form.country" placeholder="请选择准备注册公司的国家/地区">
                <el-option label="外资" value="外资"></el-option>
                <el-option label="中国香港" value="中国香港"></el-option>
                <el-option label="美国" value="美国"></el-option>
                <el-option label="新加坡" value="新加坡"></el-option>
                <el-option label="开曼" value="开曼"></el-option>
                <el-option label="BVI" value="BVI"></el-option>
                <el-option label="法国" value="法国"></el-option>
                <el-option label="马绍尔" value="马绍尔"></el-option>
                <el-option label="萨摩亚" value="萨摩亚"></el-option>
                <el-option label="日本" value="日本"></el-option>
                <el-option label="德国" value="德国"></el-option>
                <el-option label="瑞士" value="瑞士"></el-option>
                <el-option label="越南" value="越南"></el-option>
                <el-option label="英国" value="英国"></el-option>
                <el-option label="卢森堡" value="卢森堡"></el-option>
                <el-option label="塞浦路斯" value="塞浦路斯"></el-option>
                <el-option label="阿联酋" value="阿联酋"></el-option>
                <el-option label="韩国" value="韩国"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="g-sub-inner1">
            <el-form-item label="" prop="remark">
              <textarea class="g-sub-textarea" v-model="form.remark" placeholder="曾经是否了解过（目的地）公司注册，您的问题" name="" id="" cols="30" rows="10"></textarea>
            </el-form-item>
          </div>
        </el-form>
        
      </div>
      <p class="g-sub-button" @click="toSubmit">提交</p>
    </div>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      form: {},
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        direction: [{ required: true, message: '请选择注册意向', trigger: 'change' }],
        country: [{ required: true, message: '请选择准备注册公司的国家/地区', trigger: 'change' }]
        // remark: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
      },
      Dialog: false,
      options: [
        {value: 1,label: '11'}
      ],
      value: '',
      value1: ''
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    // toSubmit(){
    //   this.$refs['ruleForm'].validate((valid) => {
    //     if (valid) {
    //       this.$message({
    //         message: '提交成功！',
    //         type: 'success'
    //       });
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 7,
            name: that.form.name,
            mobile: that.form.mobile,
            location: that.form.country
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
            that.form.country = null
          })
        } else {
          return false;
        }
      })
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>

<style scoped lang="scss">
.global-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/global/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
